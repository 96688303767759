import {useEffect,useState} from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import {ApiGet} from '../../util/api';
import { useLocation } from 'react-router-dom';
import { Affix, Button, Upload } from 'antd';
import OSS from 'ali-oss';
import PubSub from 'pubsub-js';
import { useNavigate,useParams} from 'react-router-dom';
import { Dialog} from 'react-weui';
function Preview() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const urlFlag = searchParams.get('token')
    const state = urlFlag?{
        name:searchParams.get('name'),
        type:searchParams.get('type'),
        id:searchParams.get('id'),
        url:searchParams.get('url'),
    }:location.state;
    const [fileurl, setFileurl] = useState('');
    const [filename, setFilename] = useState('');
    const [filetype, setFiletype] = useState('');
    const [top, setTop] = useState(300);
    const [showDialog, setShowDialog] = useState(false);
    const navagate = useNavigate()
    const calUrl = (url) => {
        console.log(url)
        const temp = url.split('.com/')
        const temp1 = temp[1].split('?')
        const temp2 = temp1[0].split('.')
        setFileurl(url)
        setFiletype(temp2[temp2.length-1])
    }
    const download = async () => {
        const url = '/candidates/getsts?type='+state.type;
        ApiGet(url).then(async res => {
            if(res.data.code == 200){
                const alioss = {
                    region: res.data.region,
                    accessKeyId: res.data.accessKeyId,
                    accessKeySecret: res.data.accessKeySecret,
                    stsToken: res.data.stsToken,
                    bucket: res.data.bucket
                }
                const client = new OSS(alioss);
                const temp = filename.split('shouxun')
                const options = {suggestedName:temp[temp.length-1]};
                const hanlde = await window.showSaveFilePicker(options);
                console.log(hanlde)
                const result = await client.get(filename);
                console.log(result)
                const writable = await hanlde.createWritable({ keepExistingData: true });
                await writable.write(result.content);
                await writable.close();
            }
        })
    }
    const deletefile = async () => {
        const url = '/candidates/delfile?type='+state.type+'&id='+state.id;
        ApiGet(url).then(async res => {
            if(res.data.code == 200){
                if (state.type == 'resume'){
                    PubSub.publish('refreshResume',true);
                }else if(state.type == 'templet'){
                    PubSub.publish('refreshTemplet',true);
                }else if(state.type == 'format'){
                    PubSub.publish('refreshFormat',true);
                }
                navagate(-1);
                    
            }else{
                alert("删除失败")
            }
        })
    }
    useEffect(() => {
        if(urlFlag){
            localStorage.setItem('token',searchParams.get('token'));
        }
        setFilename(state.name);
        if(state.url){
            calUrl(state.url);
        }else{
            const url = '/candidates/myfile?type='+state.type+'&id='+state.id;
            ApiGet(url).then(res => {
                if(res.data.code == 200){
                    calUrl(res.data.url)
                }
            })
        }
      },[])
    const dialogTemplet = {
        title: '请确认',
        buttons: [
            {
                label: '确认',
                onClick: () => {
                    deletefile()
                },
            },
            {
                label: '取消',
                onClick: () => {
                    setShowDialog(false)
                }
            },
        ],
    };
    return (
        <div style={{width:'100%',height:'100%'}}>
            {urlFlag?null:
            <Affix style={{marginLeft:'70%',marginTop:'-33px'}} offsetTop={top} >
                <Button type="primary" onClick={ download}>下载</Button>
            </Affix>}
            {urlFlag?null:
            <Affix style={{marginLeft:'70%',marginTop:'-33px'}} offsetTop={top + 60} >
                <Button type="primary" onClick={ ()=>{setShowDialog(true)}}>删除</Button>
            </Affix>}
            {urlFlag?null:
            <Dialog type="ios"  buttons={dialogTemplet.buttons} show={showDialog}>
                   请确认是否要删除该文档？
            </Dialog>}
            
            {filetype == 'doc' || filetype == 'docx' ?
                <iframe style={{width:'100%',height:'100%'}}  
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileurl)}`}/> : 
                <DocViewer 
                documents={[
                    { uri: fileurl,  fileType: filetype,  }
                ]} 
                pluginRenderers={DocViewerRenderers}
                config={{
                    header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: false,
                    },
                    csvDelimiter: ",", // "," as default,
                    pdfZoom: {
                    defaultZoom: 1.1, // 1 as default,
                    zoomJump: 0.2, // 0.1 as default,
                    },
                    pdfVerticalScrollByDefault: true, // false as default
                }}/>
            }
          </div>
    );
}
export default Preview;