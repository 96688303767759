import 'react-weui/build/packages/react-weui.css';
import { Dialog,Progress,Toast} from 'react-weui';
import 'weui';
import {useState,useEffect,useRef} from 'react';
import UploadResume from '../uploadResume';
import UploadTemplet from '../uploadTemplet';
import TempletList from '../templetList';
import ResumeList from '../resumeList';
import {ApiGet} from '../../util/api';
import { useNavigate,useLocation } from 'react-router-dom';

function ResumeFormat({templetNameProp,templetIdProp,resumeNameProp,resumeIdProp}) {
    const navigate = useNavigate()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const urlFlag = searchParams.get('token');
    const [templetName, setTempletName] = useState(templetNameProp?templetNameProp:"");
    const [templetId, setTempletId] = useState(templetIdProp?templetIdProp:"");
    const [resumeName, setResumeName] = useState(resumeNameProp?resumeNameProp:"");
    const [resumeId, setResumeId] = useState(resumeIdProp?resumeIdProp:"");
    const [candidateName,setCandidateName] = useState("")
    const [progress,setProgress] = useState(0)
    const [showToast,setShowToast] = useState(false)
    const [errorMsg,setErrorMsg] = useState("简历生成失败")
    const [showStatus, setShowStatus] = useState(0);//0 弹出选择简历模板；1 弹出模板列表；2 弹出上传模板；3 弹出选择简历；4 弹出简历列表；5 弹出上传简历
    const dialogTemplet = {
        show: true,
        title: '请选择简历模板',
        buttons: [
            {
                label: '上传模板',
                onClick: () => {
                    setShowStatus(2)
                },
            },
            {
                label: '选择模板',
                onClick: () => {
                    setShowStatus(1)
                },
            },
        ],
    };
    const dialogResume= {
        show: true,
        title: '请选择简历',
        buttons: [
            {
                label: '上传简历',
                onClick: () => {
                    setShowStatus(5)
                },
            },
            {
                label: '选择简历',
                onClick: () => {
                    setShowStatus(4)
                },
            },
        ],
    };
    useEffect(() => {
        if(urlFlag){
            localStorage.setItem('token',searchParams.get('token'));
        }
    },[]);
    const countRef = useRef(progress);
    useEffect(() =>{
        countRef.current = progress;
    });

    useEffect(() => {
        if(templetName != "" && resumeName !=""){
            const timer = setInterval(() => {
                setProgress(countRef.current+1)
            },1000);
            const url = '/candidates/resumeFormat?templetName='+templetName+'&templetId='+templetId+'&resumeName='+resumeName+'&resumeId='+resumeId+'&candidateName='+candidateName;
            ApiGet(url).then(res => {
                clearInterval(timer) ;
                if(res.data.code == 200){
                    navigate('/preview',{ state:{url:res.data.docx,type:"format",name:res.data.name},replace:true});
                }else{
                    setErrorMsg(res.data.msg);
                    setShowToast(true);
                    // setTimeout(()=> {
                    //     setShowToast(false);
                    //     navigate(-1);
                    // }, 1000);
                }
            }).catch(err => {
                clearInterval(timer) ;
            })
            setShowStatus(6)
        }else if(templetName != "" && resumeName ==""){
            setShowStatus(3)
        }
      },[templetName,resumeName])
    return (
        
            showStatus == 0?
                <Dialog type="ios" title={dialogTemplet.title} buttons={dialogTemplet.buttons} show={showStatus == 0}>
                   您可选择已有的简历模板，也可以上传新的简历模板。
                </Dialog>
            :showStatus == 1?
                <TempletList setTempletName={setTempletName} setTempletId={setTempletId}/>
            :showStatus == 2?
                <UploadTemplet setTempletName={setTempletName} setTempletId={setTempletId}/>
            :showStatus == 3?
                <Dialog type="ios" title={dialogResume.title} buttons={dialogResume.buttons} show={showStatus == 3}>
                   您可选择已有的简历，也可以上传新的简历。
                </Dialog>
            :showStatus == 4?
                <ResumeList setResumeName={setResumeName} setResumeId={setResumeId} setCandidateName={setCandidateName}/>
            :showStatus == 5?
                <UploadResume setResumeName={setResumeName} setResumeId={setResumeId} setCandidateName={setCandidateName}/>
            :(showStatus == 6 && templetName != "" && resumeName != "")?
                <div style={{marginTop:400}}>
                    <div style={{textAlign:'center'}}>正在生成简历，请稍等...</div>
                    <Progress value={progress} />
                    <Toast icon="warn" show={showToast}>{errorMsg}</Toast>
                </div>
            :null
 
            
        
    )
}
export default ResumeFormat;